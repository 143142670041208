import React from 'react';
import { Layout } from 'billon-ui';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../../../../../ui/Modal';
import ModalHeader from '../ModalHeader';

const { Content: ContentModule } = Layout;
const { Content } = ContentModule;

const LoadingModal = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
    >
      <ModalHeader>
        <FormattedMessage
          id="Notification messages"
          defaultMessage="Notification messages"
        />
      </ModalHeader>
      <Content fluid>{children}</Content>
    </Modal>
  );
};

export default LoadingModal;
