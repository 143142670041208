import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';

// constants
import {
  DOCUMENT_TYPE,
  NOTIFICATION_PREVIEW_TYPES,
  NOTIFICATION_TEMPLATES,
  UserRoles,
} from '../../../../../../../../constraints';
import { QueryKeys } from '../../../../../../../../queryKeys';

// helpers
import { parsePatchPublicTemplateData } from '../../../../../../helpers/parsePatchPublicTemplateData';

// hooks
import useDocumentType from '../../../../../../hooks/useDocumentType';
import { usePublicNotificationSchema } from '../../../../../../hooks/schemas/usePublicNotificationSchema';
import { usePatchPublicSingleNotificationGroupTemplate } from '../../../../../../hooks/publicNotifications';

// Components
import { ProtectedComponent } from '../../../../../../../Auth/components';
import { HorizontalTenantsList } from '../HorizontalTenantsList';
import EditContentSubmitButton from '../EditContentSubmitButton';
import NotificationContent from '../NotificationContent';
import TitleContent from '../TitleContent';
import EditContentButton from '../EditContentButton';
import TemplatePreview from '../../../TemplatePreview';
import * as Styled from './styled';
import { Grid } from 'billon-ui';
const { Row, Col } = Grid;

const NotificationSection = ({
  id,
  previewType,
  titleMessageId,
  validSince,
  validUntil,
  template,
  templateTitle,
  publicDocumentType,
  tenants,
  isMinusMarginTop,
}) => {
  const { documentType } = useDocumentType();
  const queryClient = useQueryClient();

  const firstTenantId = tenants?.length > 0 && tenants[0]?.id;
  const [activeTenantId, setActiveTenantId] = useState(firstTenantId);

  const notificationParamConfig = {
    emailTitleEnabled:
      previewType === NOTIFICATION_PREVIEW_TYPES.EMAIL &&
      documentType === DOCUMENT_TYPE.PUBLIC,
    emailTitleFieldName: `${id}-emailTitle`,
    notificationContentFieldName:
      previewType === NOTIFICATION_PREVIEW_TYPES.EMAIL
        ? `${id}-emailTemplate`
        : `${id}-smsTemplate`,
    editButtonEnabled: documentType === DOCUMENT_TYPE.PUBLIC,
    formEditActivityName: `activeEdit-${id}-${previewType}`,
    formConfirmMessageId: 'Save',
    templatePreviewType:
      documentType === DOCUMENT_TYPE.PRIVATE
        ? NOTIFICATION_TEMPLATES.PRIVATE_PUBLISHED
        : NOTIFICATION_TEMPLATES.PUBLIC_PUBLISHED,
  };

  const schema = usePublicNotificationSchema({
    previewType,
    publicDocumentType,
    emailTitleFieldName: notificationParamConfig.emailTitleFieldName,
    templateFieldName: notificationParamConfig.notificationContentFieldName,
  });
  const formMethods = useForm({
    defaultValues: {
      [notificationParamConfig.formEditActivityName]: false,
      [notificationParamConfig.notificationContentFieldName]: template,
      [notificationParamConfig.emailTitleFieldName]: templateTitle,
    },
    resolver: yupResolver(schema),
  });
  const { watch, setValue, reset } = formMethods;

  const formEditActivityValue = watch(
    notificationParamConfig.formEditActivityName,
  );
  const notificationContentFieldValue = watch(
    notificationParamConfig.notificationContentFieldName,
  );
  const notificationSubjectFieldValue = watch(
    notificationParamConfig.emailTitleFieldName,
  );

  const notificationContentAreaRows = useMemo(() => {
    const length = notificationContentFieldValue?.length;
    if (length < 800) {
      return 12;
    }
    return Math.floor(length * (3 / 195));
  }, [notificationSubjectFieldValue, formEditActivityValue]);

  const handleSuccess = () => {
    setValue(notificationParamConfig.formEditActivityName, false);
    reset();
    queryClient.invalidateQueries([QueryKeys.PUBLIC_NOTIFICATION_TEMPLATE, id]);
  };
  const handleError = (statusCode) => {};

  const { mutate: editPublicNotification, isLoading: isEditing } =
    usePatchPublicSingleNotificationGroupTemplate(id, {
      onSuccess: () => {
        handleSuccess();
      },
      onError: ({ response }) => {
        const { statusCode } = response?.data;
        handleError(statusCode);
      },
    });

  const handleEdit = () => {
    editPublicNotification(
      parsePatchPublicTemplateData({
        notificationSubjectFieldValue,
        notificationContentFieldValue,
        previewType,
      }),
    );
  };

  const handleActiveTenantIdChange = (id) => {
    setActiveTenantId(id);
  };

  return (
    <FormProvider {...formMethods}>
      <>
        <Col md={5}>
          <Col md={5} style={{ minWidth: '210px' }}>
            <Row>
              <Styled.SingleTemplateHeader>
                <FormattedMessage
                  id={titleMessageId}
                  defaultMessage={titleMessageId}
                />
                {notificationParamConfig.editButtonEnabled && (
                  <EditContentButton
                    editType={previewType}
                    formEditActivityName={
                      notificationParamConfig.formEditActivityName
                    }
                    isEditing={isEditing}
                  />
                )}
              </Styled.SingleTemplateHeader>
            </Row>
          </Col>
          {notificationParamConfig.emailTitleEnabled && (
            <TitleContent
              templateTitle={templateTitle}
              name={notificationParamConfig.emailTitleFieldName}
              disabled={!formEditActivityValue}
            />
          )}
          <NotificationContent
            validSince={validSince}
            validUntil={validUntil}
            template={template}
            name={notificationParamConfig.notificationContentFieldName}
            disabled={!formEditActivityValue}
            editType={previewType}
            notificationContentAreaRows={notificationContentAreaRows}
          />
          {formEditActivityValue && (
            <ProtectedComponent
              roles={[UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
              render={false}
            >
              <EditContentSubmitButton
                notificationParamConfig={notificationParamConfig}
                isEditing={isEditing}
                onSubmit={handleEdit}
              />
            </ProtectedComponent>
          )}
        </Col>
        <Col md={7} padding="0 32px 0 32px">
          {tenants?.length > 0 && (
            <Row>
              <HorizontalTenantsList
                tenants={tenants}
                activeTenantId={activeTenantId}
                handleActiveTenantIdChange={handleActiveTenantIdChange}
              />
            </Row>
          )}
          {tenants?.length > 0 &&
            tenants.map(({ id, name }) => (
              <Row md={12}>
                <TemplatePreview
                  tenantId={id}
                  templateType={notificationParamConfig.templatePreviewType}
                  previewType={previewType}
                  template={notificationContentFieldValue}
                  templateSubject={notificationSubjectFieldValue}
                  isMinusMarginTop={isMinusMarginTop}
                  activeTenantId={activeTenantId}
                  emailTitleEnabled={notificationParamConfig.emailTitleEnabled}
                />
              </Row>
            ))}
        </Col>
      </>
    </FormProvider>
  );
};

export default NotificationSection;
