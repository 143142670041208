import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, NavLink as RouterNavLink, useHistory } from 'react-router-dom';

// constants
import {
  countryToLanguage,
  CUSTOMER,
  DOCUMENT_TYPE,
  languageToCountry,
  SETTINGS_CONSTANTS,
  UserRoles,
} from '../../constraints';
import appRoutes from '../../app-routes';

// hooks
import { useAuthenticatedContext } from '../../modules/Auth/hooks/useAuthenticated';
import useFeatureEnabled from '../../modules/Config/hooks/useFeatureEnabled';
import useFilters from '../../hooks/useFilters';
import { useConfigContext } from '../../modules/Config/hooks/useConfig';
import { useSettingsContext } from '../../modules/Settings/hooks/useSettings';

// Components
import { ProtectedComponent } from '../../modules/Auth/components';
import { Brand } from '..';
import * as Styled from './styled';
import { Dropdown as DropdownModule, Language, Layout, Utils } from 'billon-ui';

const { ChangeLanguage } = Language;
const { Header: HeaderModule } = Layout;
const { Navbar } = HeaderModule;
const { UncontrolledDropdown, DropdownItem } = DropdownModule;
const { Icon } = Utils;

const Header = () => {
  const [isOpen, setIsOpen] = useState();
  const history = useHistory();
  const { handleLogout, isAuthenticated } = useAuthenticatedContext();
  const { filters } = useFilters();

  const { featureEnabled } = useFeatureEnabled();

  const { getSettingValueByKey } = useSettingsContext();
  const {
    customer,
    isDiploma,
    enableSigning,
    enablePublic,
    enablePrivate,
    enableReports,
    enableNotificationManager,
  } = useConfigContext();

  const handleMe = () => {
    history.push('/me');
  };

  const handlePasswordChange = () => {
    history.push(appRoutes.PASSWORD_CHANGE_BLANK);
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const customLogo = getSettingValueByKey(SETTINGS_CONSTANTS.CUSTOM_LOGO);
  const customDescription = getSettingValueByKey(
    SETTINGS_CONSTANTS.DESCRIPTION,
  );
  const customPublisherName = getSettingValueByKey(
    SETTINGS_CONSTANTS.PUBLISHER_NAME,
  );

  const documentLinkUrlBase = '/documents?filters.documentTypeList=';
  const signingLinkSurfix = '';
  const DOCUMENT_LINK_URL = {
    PRIVATE: `${documentLinkUrlBase}${DOCUMENT_TYPE.PRIVATE}${signingLinkSurfix}`,
    PUBLIC: `${documentLinkUrlBase}${DOCUMENT_TYPE.PUBLIC}${signingLinkSurfix}`,
  };

  return (
    <Styled.Header>
      <Navbar expand="lg">
        <Styled.NavbarBrand
          tag={Link}
          to="/"
          customer={customer}
          onClick={handleClose}
        >
          <Styled.BrandWrapper>
            <Styled.LogoWrapper>
              <Brand logoUrl={customLogo} />
            </Styled.LogoWrapper>
            <Styled.PublicatorName>{customPublisherName}</Styled.PublicatorName>
          </Styled.BrandWrapper>

          {customDescription && (
            <Styled.Description customer={customer}>
              {customDescription}
            </Styled.Description>
          )}
        </Styled.NavbarBrand>
        <Styled.Collapse isOpen={isOpen} navbar>
          <Styled.Nav navbar>
            <ProtectedComponent
              roles={[
                UserRoles.VISITOR,
                UserRoles.AGENT,
                UserRoles.BUSINESS_ADMIN,
                UserRoles.ADMIN,
                UserRoles.DPI,
              ]}
              render={false}
            >
              {featureEnabled(null, [CUSTOMER.DIPLOMA]) && (
                <Styled.NavItem onClick={handleClose}>
                  <Styled.NavLink tag={RouterNavLink} to="/documents">
                    <FormattedMessage id="Diploma" defaultMessage="Diploma" />
                  </Styled.NavLink>
                </Styled.NavItem>
              )}

              {enablePublic && (
                <Styled.NavItem onClick={handleClose}>
                  <Styled.NavLink
                    tag={RouterNavLink}
                    to={DOCUMENT_LINK_URL.PUBLIC}
                    isActive={(match) => {
                      if (
                        !match ||
                        filters?.filters?.documentTypeList !==
                          DOCUMENT_TYPE.PUBLIC
                      ) {
                        return false;
                      }

                      return true;
                    }}
                  >
                    <FormattedMessage
                      id="Public documents"
                      defaultMessage="Public documents"
                    />
                  </Styled.NavLink>
                </Styled.NavItem>
              )}

              {enablePrivate && !isDiploma && (
                <Styled.NavItem onClick={handleClose}>
                  <Styled.NavLink
                    tag={RouterNavLink}
                    to={DOCUMENT_LINK_URL.PRIVATE}
                    isActive={(match) => {
                      if (
                        !match ||
                        filters?.filters?.documentTypeList !==
                          DOCUMENT_TYPE.PRIVATE
                      ) {
                        return false;
                      }

                      return true;
                    }}
                  >
                    <FormattedMessage
                      id="Private documents"
                      defaultMessage="Private documents"
                    />
                  </Styled.NavLink>
                </Styled.NavItem>
              )}
            </ProtectedComponent>

            <ProtectedComponent
              roles={[
                UserRoles.VISITOR,
                UserRoles.AGENT,
                UserRoles.BUSINESS_ADMIN,
                UserRoles.ADMIN,
                UserRoles.DPI,
              ]}
              render={false}
            >
              <Styled.NavItem onClick={handleClose}>
                <Styled.NavLink tag={RouterNavLink} to="/categories">
                  <FormattedMessage
                    id="Categories"
                    defaultMessage="Categories"
                  />
                </Styled.NavLink>
              </Styled.NavItem>
            </ProtectedComponent>

            {!enableSigning && featureEnabled(null, [CUSTOMER.TAURON]) && (
              <Styled.NavItem onClick={handleClose}>
                <Styled.NavLink
                  tag={RouterNavLink}
                  to={appRoutes.DAILY_REPORTS_BLANK}
                >
                  {customer === CUSTOMER.DEFAULT ? (
                    <FormattedMessage
                      id="Delivery Reports"
                      defaultMessage="Delivery Reports"
                    />
                  ) : (
                    <FormattedMessage
                      id="Daily reports"
                      defaultMessage="Daily reports"
                    />
                  )}
                </Styled.NavLink>
              </Styled.NavItem>
            )}

            {enablePrivate && (
              <Styled.NavItem onClick={handleClose}>
                <Styled.NavLink tag={RouterNavLink} to="/recipients">
                  {
                    {
                      [CUSTOMER.DIPLOMA]: (
                        <FormattedMessage
                          id="Students"
                          defaultMessage="Students"
                        />
                      ),
                      [CUSTOMER.DEFAULT]: (
                        <FormattedMessage
                          id="Recipients"
                          defaultMessage="Recipients"
                        />
                      ),
                      [CUSTOMER.TAURON]: (
                        <FormattedMessage
                          id="Recipients"
                          defaultMessage="Recipients"
                        />
                      ),
                    }[customer]
                  }
                </Styled.NavLink>
              </Styled.NavItem>
            )}

            {[CUSTOMER.DEFAULT, CUSTOMER.TAURON, CUSTOMER.DIPLOMA].includes(
              customer,
            ) && (
              <ProtectedComponent roles={[UserRoles.DPI]} render={false}>
                <Styled.NavItem onClick={handleClose}>
                  <Styled.NavLink tag={RouterNavLink} to="/gdpr">
                    <FormattedMessage id="GDPR" defaultMessage="GDPR" />
                  </Styled.NavLink>
                </Styled.NavItem>
              </ProtectedComponent>
            )}
            <ProtectedComponent roles={[UserRoles.ADMIN]} render={false}>
              <Styled.NavItem onClick={handleClose}>
                <Styled.NavLink tag={RouterNavLink} to="/users">
                  <FormattedMessage
                    id="Administrators"
                    defaultMessage="Administrators"
                  />
                </Styled.NavLink>
              </Styled.NavItem>
            </ProtectedComponent>
            {enableReports && (
              <Styled.NavItem onClick={handleClose}>
                <Styled.NavLink tag={RouterNavLink} to="/reports">
                  <FormattedMessage id="Reports" defaultMessage="Reports" />
                </Styled.NavLink>
              </Styled.NavItem>
            )}
            {featureEnabled('observer', null) && (
              <Styled.NavItem onClick={handleClose}>
                <Styled.NavLink tag={RouterNavLink} to="/requests">
                  <FormattedMessage id="Requests" defaultMessage="Requests" />
                </Styled.NavLink>
              </Styled.NavItem>
            )}
            {enableNotificationManager && (
              <ProtectedComponent
                roles={[UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
                render={false}
              >
                <Styled.NavItem onClick={handleClose}>
                  <Styled.NavLink
                    tag={RouterNavLink}
                    to={appRoutes.NOTIFICATIONS}
                  >
                    <FormattedMessage
                      id="Notifications"
                      defaultMessage="Notifications"
                    />
                  </Styled.NavLink>
                </Styled.NavItem>
              </ProtectedComponent>
            )}
          </Styled.Nav>
          {isAuthenticated ? (
            <UncontrolledDropdown>
              <Styled.DropdownToggle caret>
                <Icon name="user-circle" fontSize="32px" />
              </Styled.DropdownToggle>
              <Styled.DropdownMenu right>
                <DropdownItem onClick={handlePasswordChange}>
                  <FormattedMessage
                    id="Change password"
                    defaultMessage="Change password"
                  />
                </DropdownItem>
                <DropdownItem onClick={handleMe}>
                  <FormattedMessage
                    id="User details"
                    defaultMessage="User details"
                  />
                </DropdownItem>
                <DropdownItem onClick={handleLogout}>
                  <FormattedMessage id="Logout" defaultMessage="Logout" />
                </DropdownItem>
              </Styled.DropdownMenu>
            </UncontrolledDropdown>
          ) : null}
          <ChangeLanguage
            countryToLanguage={countryToLanguage}
            languageToCountry={languageToCountry}
          />
          {isOpen ? (
            <Styled.Icon name="times" onClick={handleOpen} />
          ) : (
            <Styled.Icon name="bars" onClick={handleOpen} />
          )}
        </Styled.Collapse>
      </Navbar>
    </Styled.Header>
  );
};

export default Header;
