import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import appRoutes from '../../../../../../../../app-routes';
import {
  UserRoles,
  NOTIFICATION_MODULE_MENU_LABEL,
} from '../../../../../../../../constraints';

import useFilters from '../../../../../../../../hooks/useFilters';

// Components
import { ProtectedComponent } from '../../../../../../../Auth/components';
import * as Styled from './styled';

const SetNewContentButton = () => {
  const { filters, navigateWithNewSearchQuery } = useFilters();

  const handleClick = () => {
    navigateWithNewSearchQuery(
      filters,
      appRoutes.NOTIFICATION_TEMPLATE_MANAGE_NEW,
    );
  };

  return (
    <ProtectedComponent
      roles={[UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
      render={false}
    >
      <Styled.ButtonCol sm={10} md={12}>
        <Styled.FlexEndButton onClick={handleClick}>
          <FormattedMessage
            id={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_NEW}
            defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_NEW}
          />
        </Styled.FlexEndButton>
      </Styled.ButtonCol>
    </ProtectedComponent>
  );
};

export default SetNewContentButton;
