import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import appRoutes from '../../../../../../../../app-routes';
import {
  UserRoles,
  NOTIFICATION_MODULE_MENU_LABEL,
} from '../../../../../../../../constraints';

// hooks
import useFilters from '../../../../../../../../hooks/useFilters';

// Components
import { ProtectedComponent } from '../../../../../../../Auth/components';
import * as Styled from './styled';

const EditRestoreContentButton = ({ contentId, validSince, validUntil }) => {
  const { navigateWithSearchQuery } = useFilters();

  const isEdit = new Date(validSince) > Date.now();
  const isRestore =
    !isEdit &&
    new Date(validSince) < Date.now() &&
    !!validUntil &&
    new Date(validUntil) < Date.now();

  const handleEditClick = () => {
    navigateWithSearchQuery(
      { filters: { contentId } },
      appRoutes.NOTIFICATION_TEMPLATE_MANAGE_EDIT,
    );
  };

  const handleRestoreClick = () => {
    navigateWithSearchQuery(
      { filters: { contentId } },
      appRoutes.NOTIFICATION_TEMPLATE_MANAGE_RESTORE,
    );
  };

  if (!isEdit && !isRestore) {
    return null;
  }

  return (
    <ProtectedComponent
      roles={[UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
      render={false}
    >
      <Styled.AllCol md={12}>
        <Styled.FlexEndRow sm={12} md={12}>
          <Styled.ButtonCol sm={12} md={4}>
            {isEdit && (
              <Styled.FlexEndButton onClick={handleEditClick} outline block>
                <FormattedMessage
                  id={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_MODIFY}
                  defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_MODIFY}
                />
              </Styled.FlexEndButton>
            )}
            {isRestore && (
              <Styled.FlexEndButton onClick={handleRestoreClick} outline block>
                <FormattedMessage
                  id={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_RESTORE_VERSION}
                  defaultMessage={
                    NOTIFICATION_MODULE_MENU_LABEL.CONTENT_RESTORE_VERSION
                  }
                />
              </Styled.FlexEndButton>
            )}
          </Styled.ButtonCol>
        </Styled.FlexEndRow>
      </Styled.AllCol>
    </ProtectedComponent>
  );
};

export default EditRestoreContentButton;
